<ion-content>
    <ion-header class="ion-no-border header-top"> 
      <ion-toolbar mode="md" color="pure-white">
          <ion-buttons slot="start">
              <ion-button color="success" routerDirection="back" (click)="goBack()">
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-down">
                </ion-icon>
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-up">
                </ion-icon>
              </ion-button>
          </ion-buttons>
          <ion-title color="dark" class="freedomtitle">
              Tutorials
          </ion-title>
      </ion-toolbar>
  </ion-header>
  <ion-item class="divider ion-hide-sm-up"></ion-item>
  <p class="subtitle mobile-align">Spending Controls (ND1) - Earned Income Rules Based System</p>
  <ion-grid class="mobile-align">
      <ion-row>
          <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <h2>ND1 Spending Controls</h2>
          </ion-col>
      </ion-row>
      <ion-row>
          <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <p>Money should be managed via ratios (ralative values) not dollars and cents (absolute
                  values). Wealth is created through production not consumption.
              </p>
              <p>ND1 is the first formula in our non discretionary rules based system. 
              It is how we manage our Earned Income (money we work for: ie;  wages, tips, 
              salary, commissions, bonuses, 1099 income) to begin the process of mastering 
              our money.
              </p>
              <p>
              Wealth is created through production not consumption. Money is always looking 
              for a place to go, flowing from dumb to smart or where its treated best.
              We become its master by giving it purpose and reason for existence and not 
              letting money with different purposes co-mingle.
              </p>
              <p>
              We do this by setting up 4 separate accounts and applying the following 
              ND1 rules to every deposit (of Earned Income).
              </p>
              <p>Rule: Live off of 70% of your income.
              </p>
          </ion-col>
          <!-- <ion-col class="videoPlaceholder" size-lg="6" size-md="6" size-sm="12" size-xl="6" size-xs="12">
              <ion-img src="assets/images/videoPlaceholder.png"></ion-img>
              <ion-icon src="assets/icon/play.svg"></ion-icon>
          </ion-col> -->
      </ion-row>
      <ion-item lines="full"></ion-item>
      <ion-row>
          <ion-col  size-lg="6" size-md="6" size-sm="12" size-xl="6" size-xs="12">
              <h2>Our TWF system uses the following ratios of 10/10/10/70</h2>
          </ion-col>
      </ion-row>
      <ion-row class="listratio">
          <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <ul>
                  <li>10% giving account - to help those in need</li>
                  <li>10% wealth account (20% when debt free)</li>
                  <li>10% debt account - to accelerate debt payoff</li>
                  <li>70% living expenses - to cover bills</li>
              </ul>
          </ion-col>
          <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <ion-img src="assets/images/PieChart.png"></ion-img>
              <ion-list>
              <ion-item lines="none">
                      <div class="legend-item fullwidth">
                          <div class="box" [style.background-color]="'#66C6FF'"></div>
                          <div class="boxText">Bills</div>
                          <div class="boxValue">70%</div>
                      </div>
              </ion-item>
              <ion-item lines="none">
                      <div class="legend-item fullwidth">
                          <div class="box" [style.background-color]="'#FFCF33'"></div>
                          <div class="boxText">Giving</div>
                          <div class="boxValue">10%</div>
                      </div>
              </ion-item>
              <ion-item lines="none">
                      <div class="legend-item fullwidth">
                          <div class="box" [style.background-color]="'#5CE593'"></div>
                          <div class="boxText">Wealth</div>
                          <div class="boxValue">10%</div>
                      </div>
              </ion-item>
              <ion-item lines="none">
                      <div class="legend-item fullwidth">
                          <div class="box" [style.background-color]="'#FF524D'"></div>
                          <div class="boxText">Debt Accelerator</div>
                          <div class="boxValue">10%</div>
                      </div>
              </ion-item>
          </ion-list>
          </ion-col>
      </ion-row>
      <ion-item lines="full"></ion-item>
      <ion-row>
          <ion-col>
              <p>You are free to customize the above ratios but that is the proven and recommended formula. You can also add custom accounts as well.</p>
  
              <h2>Action Steps:</h2>
              <ol>
                  <li>Open new accounts, close unneeded ones, consolidate what can be consolidated.</li>
                  <li>Label the accounts at your bank, brokerage or fintech solution.</li>
                  <li>Schedule automated transfers according to the ratios above to trigger every time you make a deposit.</li>
              </ol>
                  
         
          </ion-col>
          <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <h2>How to apply ND1 to variable income</h2>
          </ion-col>
      </ion-row>
      <ion-row>
          <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <p>
              If you have variable income from a job or business, you can identify the baseline (low range) 
              income amount over a period of time and setup your ND1 ratios and automated transfers accordingly. 
              Then every month or quarter, identify the difference between the baseline trend and the higher 
              spiked payment periods - whatever that difference is apply ND1 ratios via manual transfers. 
              Test until you figure out a system that works best but the key is setting up a non discretionary 
              system, sticking to it and applying it consistently.
              </p>
          </ion-col>
      </ion-row>
      <!-- 
      <ion-row>
          <ion-col class="educationtutorial" size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
              <h2>Educational materials</h2>
              <ion-button mode="ios" shape="round" type="button" class="cancel ion-margin" (click)="goBack()">
                  Explore more
                  <ion-icon name="chevron-forward" slot="end"></ion-icon>
              </ion-button>
          </ion-col>
      </ion-row>
      <ion-row>
          <ion-col class="educationcards" size-lg="6" size-md="6" size-sm="12" size-xl="6" size-xs="12">
              <ion-card>
                  <img src="../../assets/images/ed1.png" class="fullwidth">
                  <ion-card-header>
                      <h2>True Wealth Formula - Bootleg</h2>
                      <ion-icon name="bookmark-outline" color="success"></ion-icon>
                  </ion-card-header>
                  <ion-card-content>
                      Off-grid and uncensored, the perfect TWF starter training for new Wealth Builders
                      <div>
                          <ion-chip outline="true">Investing</ion-chip>
                          <ion-chip outline="true">Finance</ion-chip>
                          <ion-chip outline="true">Assets</ion-chip>
                          <ion-chip outline="true">Stocks</ion-chip>
                      </div>
                  </ion-card-content>
              </ion-card>
          </ion-col>
          <ion-col class="educationcards" size-lg="6" size-md="6" size-sm="12" size-xl="6" size-xs="12">
              <ion-card>
                  <img src="../../assets/images/ed2.png" class="fullwidth">
                  <ion-card-header>
                      <h2>True Wealth Formula - Bootleg</h2>
                      <ion-icon name="bookmark-outline" color="success"></ion-icon>
                  </ion-card-header>
                  <ion-card-content>
                      Off-grid and uncensored, the perfect TWF starter training for new Wealth Builders
                      <div>
                          <ion-chip outline="true">Investing</ion-chip>
                          <ion-chip outline="true">Finance</ion-chip>
                          <ion-chip outline="true">Assets</ion-chip>
                          <ion-chip outline="true">Stocks</ion-chip>
                      </div>
                  </ion-card-content>
              </ion-card>
          </ion-col>
          <ion-col class="educationcards" size-lg="6" size-md="6" size-sm="12" size-xl="6" size-xs="12">
              <ion-card>
                  <img src="../../assets/images/ed3.png" class="fullwidth">
                  <ion-card-header>
                      <h2>TWF Implementation</h2>
                      <ion-icon name="bookmark-outline" color="success"></ion-icon>
                  </ion-card-header>
                  <ion-card-content>
                      Your step by step blueprint to personal and financial freedom
                      <div>
                          <ion-chip outline="true">Budget</ion-chip>
                          <ion-chip outline="true">Business model</ion-chip>
                          <ion-chip outline="true">Liabilities</ion-chip>
                      </div>
                  </ion-card-content>
              </ion-card>
          </ion-col>
      </ion-row> 
      -->
  </ion-grid>
  </ion-content>