<ion-content>
  <!-- <ion-icon class="ion-hide-sm-up mobilehandle" src="assets/icon/Handle.svg"></ion-icon> -->
  <ion-header class="ion-no-border header-top"> 
    <ion-toolbar mode="md" color="pure-white">
        <ion-buttons slot="start">
            <ion-button color="success" routerDirection="back" (click)="goBack()">
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-down">
                </ion-icon>
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-up">
                </ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="dark" class="freedomtitle">
            Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-item class="divider ion-hide-sm-up"></ion-item>
<p class="subTitle mobile-align">Lifestyle Number</p>
<!-- <div class="overall mobile-align">
  <div class="overalltext">Instructions:</div>
</div> -->
<ion-list class="mobile-align">
        <p>
            Your Lifestyle Number is the total monthly Unearned Income (money that works for
            you) to cover wants and luxuries. It is the second specific target number and milestone
            in the TWF money mastery system and is used to plot your financial independence plan.
        </p>
        &nbsp;
        <p>
            It should not be confused with Freedom Number, which relates to core needs based
            on Maslow's Hierarchy of Needs model (ie; “food, clothing, shelter”).
        </p>
        &nbsp;
        <p>
            Your Lifestyle Number includes your Freedom Number plus the 3 core luxuries of Travel,
            Shopping and Entertainment. But it can include literally anything you want.
        </p>
        &nbsp;
        <p>
            How it’s used? Your Lifestyle Number is used to plot your progress when running
            calculations and projections on your existing Assets as well as “forecasting” and
            running simulations on possible A/B “what if” scenarios.
        </p>
        &nbsp;
</ion-list>
</ion-content>