<ion-header>
    <ion-toolbar #j_51>
        <ion-title #j_52>
            Budgeting Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_46>
        <ion-card-content #j_47>
            <ion-card-title #j_48>
                Spending Controls (ND1) - Earned Income Rules Based System
            </ion-card-title>
            <div #j_49>
                <p>
                    Money should be managed via ratios (ralative values) not dollars and cents (absolute
                    values). Wealth is created through production not consumption. ND1 is the first formula
                    in our non discretionary rules based system. It is how we manage our Earned Income
                    (money we work for: ie; wages, tips, salary, commissions, bonuses, 1099 income) to
                    begin the process of mastering our money.
                </p>
                <br>
                <p>
                    Wealth is created through production not consumption. Money is always looking for
                    a place to go, flowing from dumb to smart or where its treated best. We become its
                    master by giving it purpose and reason for existence and not letting money with different
                    purpose co-mingle.
                </p>
                <br>
                <p>
                    We do this by setting up 4 separate accounts and applying the following ND1 rules
                    to every deposit (of Earned Income).
                </p>
                <br>
                <p>
                    Live off of 70% of your income.
                </p>
                <br>
                <p>
                    Our TWF system uses the following ratios of 10/10/10/70
                </p>
                <ol>
                    <li>
                        10% giving account - to help those in need
                    </li>
                    <li>
                        10% wealth account (20% when debt free) - to develop Unearned Income
                    </li>
                    <li>
                        10% debt account - to accelerate debt payoff
                    </li>
                    <li>
                        70% living expenses - to cover bills
                    </li>
                </ol>
                <p>
                    You are free to customize the above ratios but that is the proven and recommended
                    formula. You can also add custom accounts as well.
                </p>
                <p>
                    Action Steps:
                </p>
                <ol>
                    <li>
                        Open new accounts, close uneeded ones, consolidate what can be consolidated.
                    </li>
                    <li>
                        Label the accounts at your bank, brokerage or fintech solution.
                    </li>
                    <li>
                        Setup automated transfers according to the ratios above to trigger every time you
                        make a deposit.
                    </li>
                </ol>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_54>
        <ion-row #j_55>
            <ion-col #j_56 class="ion-no-padding">
                <ion-button #j_57 color="dark" expand="block" type="button" class="ion-margin" (click)="close()">
                    Close
                </ion-button>
            </ion-col>
            <ion-col #j_58 class="ion-no-padding">
                <ion-button #j_59 color="dark" expand="block" type="button" class="ion-margin" (click)="openMore()">
                    More Info
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>