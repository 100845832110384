<ion-content>
  <!-- <ion-icon class="ion-hide-sm-up mobilehandle" src="assets/icon/Handle.svg"></ion-icon> -->
  <ion-header class="ion-no-border header-top"> 
    <ion-toolbar mode="md" color="pure-white">
        <ion-buttons slot="start">
            <ion-button color="success" routerDirection="back" (click)="goBack()">
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-down">
                </ion-icon>
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-up">
                </ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="dark" class="freedomtitle">
            Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-item class="divider ion-hide-sm-up"></ion-item>
<p class="subTitle mobile-align">Freedom Number</p>
<!-- <div class="overall mobile-align">
  <div class="overalltext">Instructions:</div>
</div> -->
<ion-list class="mobile-align">
      <p>
          Your Freedom Number is the minimum monthly Unearned Income (money that works for
          you) needed to cover your core needs. It is the first specific target number and
          milestone in the TWF money mastery system and is used to plot your financial independence
          plan.
      </p>
      &nbsp;
      <p>
          Using Maslow's Hierarchy of Needs model (ie; “food, clothing, shelter”), the wealth
          builder makes a clear distinction between Freedom (core needs) and Lifestyle (luxury
          wants).
      </p>
      &nbsp;
      <p>
          Ie; If my core needs are covered, I no longer need to “work for money”, I am free
          to contribute to society or follow a passion. I am working for fulfillment not survival.
          If for some reason I cannot physically work, my core needs are covered.
      </p>
      &nbsp;
      <p>
          How it’s used? Your Freedom Number is used to plot your progress when running calculations
          and projections on your existing Assets as well as “forecasting” and running simulations
          on possible A/B “what if” scenarios.
      </p>
      &nbsp;
</ion-list>
</ion-content>