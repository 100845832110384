<ion-header>
    <ion-toolbar #j_88>
        <ion-title #j_89>
            Lifestyle Number Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_83>
        <ion-card-content #j_84>
            <ion-card-title #j_85>
            </ion-card-title>
            <ion-text #j_86>
                Your Lifestyle Number is the total monthly Unearned Income (money that works for
                you) to cover wants and luxuries. It is the second specific target number and milestone
                in the TWF money mastery system and is used to plot your financial independence plan.
                <br>
                <br>
                It should not be confused with Freedom Number, which relates to core needs based
                on Maslow's Hierarchy of Needs model (ie; “food, clothing, shelter”).
                <br>
                <br>
                Your Lifestyle Number includes your Freedom Number plus the 3 core luxuries of Travel,
                Shopping and Entertainment. But it can include literally anything you want.
                <br>
                <br>
                How it’s used? Your Lifestyle Number is used to plot your progress when running
                calculations and projections on your existing Assets as well as “forecasting” and
                running simulations on possible A/B “what if” scenarios.
            </ion-text>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_91>
        <ion-row #j_92>
            <ion-col #j_93 class="ion-no-padding">
                <ion-button #j_94 color="dark" expand="block" type="button" class="ion-margin" (click)="close()">
                    Close
                </ion-button>
            </ion-col>
            <ion-col #j_95 class="ion-no-padding">
                <ion-button #j_96 color="dark" expand="block" type="button" class="ion-margin" (click)="openMore()">
                    More Info
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>