<ion-content>
  <!-- <ion-icon class="ion-hide-sm-up mobilehandle" src="assets/icon/Handle.svg"></ion-icon> -->
  <ion-header class="ion-no-border header-top"> 
    <ion-toolbar mode="md" color="pure-white">
        <ion-buttons slot="start">
            <ion-button color="success" routerDirection="back" (click)="goBack()">
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-down">
                </ion-icon>
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-up">
                </ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="dark" class="freedomtitle">
            Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-item class="divider ion-hide-sm-up"></ion-item>
<p class="subTitle mobile-align">Debt payoff</p>
<div class="overall mobile-align">
  <div class="overalltext">Instructions:</div>
</div>
<p class="mobile-align">Below is your debt payoff order. Start with the first debt on the list (the initial
  “target debt”). You'll pay the New Monthly Pymt amount (ie; the Min Payment + your
  Accelerator Payment) until paid off at which time you'll roll debt 1 total New Monthly
  Pymt amount into debt 2 and so fourth. Continue making minimum payments on your other
  debts while paying down and accelerating your &quot;target debt&quot;. Print or screen
  shot a copy of this plan as it may change as you update your Liability account balances
  monthly.</p>
  <div class="overall mobile-align">
    <div class="overalltext">Tips:</div>
  </div>
  <ol class="mobile-align">
    <li>Increase your Accelerator Amount to eliminate debt even faster</li>
    <li>Increase your Earned Income to apply more Accelerator towards debt via ND1 ratios</li>
    <li>Roll FCF from debt payoff into T2 Cash Flow Assets to compound and accelerate your Unearned Income growth and reach your Freedom and Lifestyle Numbers even faster</li>
  </ol>
</ion-content>