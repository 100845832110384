import { Directive, Input } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { NgxMaskService } from 'ngx-mask';

@Directive({
    selector: 'ion-input'
})
export class CustomMaskDirective {
    @Input() mask: any;
    @Input() thousandSeparator: any;
    constructor(
        private control: IonInput,
        private maskService: NgxMaskService,
    ) {
        this.maskService.allowNegativeNumbers = true;
        this.maskService.thousandSeparator = this.thousandSeparator || ',';
        this.control.ionChange.subscribe((r: string) => {
            if (this.control.value.toString() === ".") {
                this.control.value = "0.";
            }
            if (this.mask)
                this.control.value = this.maskService.applyMask(this.control.value.toString(), this.mask);
        });
    }

}