<ion-header>
    <ion-toolbar #j_37>
        <ion-title #j_38>
            Compound Return Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_33>
        <ion-card-content #j_34>
            <p>
                <strong>
                    Notes updated AUG 19, 2020:
                </strong>
            </p>
            &nbsp;
            <p>
                This calculator focuses on creating compound passive income through cash flow assets.
                Its flexibility allows multiple scenarios to be calculated. If on mobile device,
                we recommend taking screen shots to save your data as the calculator export function
                only works via browser.
            </p>
            &nbsp;
            <p>
                The verbiage &ldquo;purchase of new assets&rdquo;, has a slightly different meaning
                depending on which
                <strong>
                    Investment Type
                </strong>
                is selected. For Portfolio, the amount is simply added to the balance whereas for
                Securities and Units, new individual assets are purchased. Units can be used
                for Real Estate rentals,Private Money Lending notes or virtually any other asset
                type you can think of.
            </p>
            &nbsp;
            <p>
                The
                <strong>
                    Compound/DRIP
                </strong>
                button allows income from existing assets to be reinvested into purchase of new
                assets according to the selected Distribution Frequency.
            </p>
            &nbsp;
            <p>
                The
                <strong>
                    Fractional Reinvestment
                </strong>
                button (available when Securities and Units are selected) will purchase new
                assets on a fractional basis when Yes is selected. When No is selected, only whole
                assets will be purchased. Selecting No is ideal when the price of the asset is large
                (like a real estate rental unit) or when fractional purchases are not possible. In
                this scenario, the funds received from existing asset income, monthly contributions
                and/or yearly contributions are held temporarily until the entire purchase price
                of the new asset is reached. Think of it as a savings account. The calculator will
                &ldquo;save&rdquo; these funds until the month where the savings exceeds the cost
                of the asset, at which time the asset will be purchased in whole. This is all done
                behind the scenes in the calculator. The savings amount accumulates silently and
                has no output field in the calculator.
            </p>
            &nbsp;
            <p>
                The
                <strong>
                    Distribution Frequency
                </strong>
                button will determine how often income from existing assets will be received as
                well as the rate of compounding. For reinvestment, the calculator uses a monthly
                computational cycle regardless of which Distribution Frequency is selected. This
                allows for purchase of new assets on a monthly basis by applying the amounts entered
                into Monthly Income fields (Wealth Account, Debt Accelerator, Debt Calculator Rollover,
                Monthly Unearned Income) so funds are not sitting waiting to be invested if a longer
                Distribution Frequency is selected.
            </p>
            &nbsp;
            <p>
                The
                <strong>
                    Freedom Number
                </strong>
                and
                <strong>
                    Lifestyle Number
                </strong>
                are monthly amounts. The calculator will check every month if the income from existing
                assets matches or exceeds these numbers. Inflation is not currently calculated into
                these numbers. If the Freedom and/or Lifestyle numbers are not met during the Years
                Invested provided, an error message will appear. Otherwise, the calculator will display
                the exact month and year These goals are reached.
            </p>
            &nbsp;
            <p>
                The
                <strong>
                    Annual Contribution
                </strong>
                amount is a lump sum added each year to purchase new assets and will be calculated
                at the end of the year cycle.
            </p>
            &nbsp;
            <p>
                <strong>
                    Annual Increase
                </strong>
                is the percent yearly increase of the annual contribution.
            </p>
            &nbsp;
            <p>
                <strong>
                    Expected Annual Increase
                </strong>
                is the expected percentage increase in the base price or balance of the asset.
            </p>
            &nbsp;
            <p>
                <strong>
                    Current (Dividend) Yield
                </strong>
                is the current percentage yield of the asset.
            </p>
            &nbsp;
            <p>
                <strong>
                    Yield/Dividend Growth Rate
                </strong>
                is the expected annual percentage increase in the yield.
            </p>
            &nbsp;
            <p>
                <strong>
                    Tax Rate
                </strong>
                value if entered, will calculate based on income received from existing assets and
                will add a Tax column to the results table showing the tax paid for the year. That
                tax is deducted from the Income and the New Balance columns, so the output amounts
                will show a post-tax number. The number of Shares/Units will also show a reduction
                if &ldquo;Sell shares/units to pay tax&rdquo; is marked Yes.
            </p>
            &nbsp;
            <p>
                <strong>
                    Risk Adjusted Basis
                </strong>
                is the adjusted entry price of the asset when income is factored in. Meaning, it
                shows the impact of reinvested income (dividends, rents, interest, etc) on original
                capital investment. As income is reinvested the original capital at risk is lowered.
            </p>
            &nbsp;
            <p>
                When
                <strong>
                    Multiple Assets
                </strong>
                are entered, the calculator computes the results of each asset independently of
                other assets, meaning the results of one asset have no bearing on another. At the
                end of each year, the results are calculated by combining the values of all assets.
                Some values are simply added together: annual contribution, balance, basis, income,
                risk adjusted basis, tax paid, total distribution. Other values are computed by first
                assigning a percentage depending on their position size in relation to the entire
                balance of the combined assets, then added together to give a total: Yield, return
                and yield on cost.
            </p>
            &nbsp;
            <p>
                <strong>
                    Annual Withdrawal
                </strong>
                , is helpful to account for an annual income received. When entered it will be withdrawn
                and deducted from the balance of the asset(s). If portfolio, this amount will simply
                be subtracted from the year-end-balance. If Securities or Unit, the asset will
                be sold in order to pay out the withdrawal.
            </p>
            &nbsp;
            <p>
                <strong>
                    Starting Year
                </strong>
                is the year the withdrawal will begin.
            </p>
            &nbsp;
            <p>
                <strong>
                    Volatility/Drawdown
                </strong>
                is the percentage decrease the asset(s) are expected to encounter.
            </p>
            &nbsp;
            <p>
                The
                <strong>
                    How Often?
                </strong>
                field is measured in years and is the input that controls how often the volatility/drawdown
                decrease is applied.
            </p>
            &nbsp;
            <p>
                Some mathematical discrepancies expected due to rounding and complexity and timing
                of multiple calculations.
            </p>
            &nbsp;
            <p>
                <strong>
                    As of July 15, 2020:
                </strong>
                &nbsp;Logic adjustments were made so that all investment types (regardless of Portfolio,
                Securities, or Units) follow the same algorithm. The nominal value of the yield
                is calculated and the yield growth rate is applied to this nominal value. This affected
                the following outputs:
            </p>
            &nbsp;
            <ul>
                <li>
                    Total Dividends/Total Income
                </li>
                <li>
                    Total Distribution
                </li>
                <li>
                    Yield on Cost
                </li>
                <li>
                    Risk Adjusted Basis
                </li>
            </ul>
            <p>
                This change was made to more accurately simulate real world scenarios and adapt
                industry standards for yield calculations.
            </p>
            &nbsp;
            <p>
                Rounding was turned off in the algorithm. Rounding is only applied at the very end
                of the cycle and right before the final values are displayed. This was done to be
                as accurate as possible with every calculation. It also reduces the output difference
                between Portfolio and Securities scenarios
            </p>
            &nbsp;
            <p>
                Dividend per share and income per unit were added in the results section and the
                output table. This shows the nominal value for each individual share or unit for
                a given year. This value is displayed to the tenth of a cent.
            </p>
            &nbsp;
            <p>
                <strong>
                    Last updated NOV 22, 2020:
                </strong>
            </p>
            &nbsp;
            <p>
                New Compound Return module runs on user's balance sheet so some fields below are now located inside the individual
                Asset account settings.
            </p>
            &nbsp;
            <p>
                This calculator focuses on creating compound passive income through cash flow assets. Its flexibility allows
                multiple scenarios to be calculated. If on mobile device, we recommend taking screen shots to save your data as the
                calculator export function only works via browser.
            </p>
            &nbsp;
            <p>
                The verbiage "purchase of new assets" has a slightly different meaning depending on which Investment Type is
                selected. For Portfolio, the amount is simply added to the balance whereas for Securities and Units, new
                individual assets are purchased. Units can be used for Real Estate rentals, Private Money Lending notes or virtually
                any other asset type you can think of.
            </p>
            &nbsp;
            <p>
                The Compound/DRIP button allows income from existing assets to be reinvested into purchase of new assets according
                to the selected Distribution Frequency.
            </p>
            &nbsp;
            <p>
                The Fractional Reinvestment button (available when Securities and Units are selected) will purchase new assets
                on a fractional basis when Yes is selected. When No is selected, only whole assets will be purchased. Selecting No
                is ideal when the price of the asset is large (like a real estate rental unit) or when fractional purchases are not
                possible. In this scenario, the funds received from existing asset income, monthly contributions and/or yearly
                contributions are held temporarily until the entire purchase price of the new asset is reached. Think of it as a
                savings account. The calculator will "save" these funds until the month where the savings exceeds the cost of the
                asset, at which time the asset will be purchased in whole. This is all done behind the scenes in the calculator. The
                savings amount accumulates silently and has no output field in the calculator.
            </p>
            &nbsp;
            <p>
                The Distribution Frequency button will determine how often income from existing assets will be received as well as
                the rate of compounding. For reinvestment, the calculator uses a monthly computational cycle regardless of which
                Distribution Frequency is selected. This allows for purchase of new assets on a monthly basis by applying the
                amounts entered into Monthly Income fields (Wealth Account, Debt Accelerator, Debt Calculator Rollover, Monthly
                Unearned Income) so funds are not sitting waiting to be invested if a longer Distribution Frequency is selected.
            </p>
            &nbsp;
            <p>
                The Freedom Number and Lifestyle Number are monthly amounts. The calculator will check every month if the income
                from existing assets matches or exceeds these numbers. Inflation is not currently calculated into these numbers. If
                the Freedom and/or Lifestyle numbers are not met during the Years Invested provided, an error message will appear.
                Otherwise, the calculator will display the exact month and year these goals are reached.
            </p>
            &nbsp;
            <p>
                The Annual Contribution amount is a lump sum added each year to purchase new assets and will be calculated at the
                end of the year cycle.
            </p>
            &nbsp;
            <p>
                Annual Increase is the percent yearly increase of the annual contribution.
            </p>
            &nbsp;
            <p>
                Expected Annual Increase is the expected percentage increase in the base price or balance of the asset.
            </p>
            &nbsp;
            <p>
                Current (Dividend) Yield is the current percentage yield of the asset.
            </p>
            &nbsp;
            <p>
                Yield/Dividend Growth Rate is the expected annual percentage increase in the yield.
            </p>
            &nbsp;
            <p>
                Tax Rate value if entered, will calculate based on income received from existing assets and will add a Tax column to
                the results table showing the tax paid for the year. That tax is deducted from the Income and the New Balance
                columns, so the output amounts will show a post-tax number. The number of Shares/Units will also show a reduction if
                "Sell shares/units to pay tax" is marked Yes.
            </p>
            &nbsp;
            <p>
                Risk Adjusted Basis is the adjusted entry price of the asset when income is factored in. Meaning, it shows the
                impact of reinvested income (dividends, rents, interest, etc) on original capital investment. As income is
                reinvested the original capital at risk is lowered.
            </p>
            &nbsp;
            <p>
                When Multiple Assets are entered, the calculator computes the results of each asset independently of other assets,
                meaning the results of one asset have no bearing on another. At the end of each year, the results are calculated by
                combining the values of all assets. Some values are simply added together: annual contribution, balance, basis,
                income, risk adjusted basis, tax paid, total distribution. Other values are computed by first assigning a percentage
                depending on their position size in relation to the entire balance of the combined assets, then added together to
                give a total: Yield, return and yield on cost.
            </p>
            &nbsp;
            <p>
                Annual Withdrawal, is helpful to account for an annual income received. When entered it will be withdrawn and
                deducted from the balance of the asset(s). If portfolio, this amount will simply be subtracted from the
                year-end-balance. If Securities or Unit, the asset will be sold in order to pay out the withdrawal.
            </p>
            &nbsp;
            <p>
                Starting Year is the year the withdrawal will begin.
            </p>
            &nbsp;
            <p>
                Volatility/Drawdown is the percentage decrease the asset(s) are expected to encounter.
            </p>
            &nbsp;
            <p>
                The How Often? field is measured in years and is the input that controls how often the volatility/drawdown decrease
                is applied. Note: The drawdown is applied at the very end of the period (year) so its affect can be more dramatic
                than natural market volatility which tends to happen at various times throughout a given year giving time to
                correct. Currently in the calculator when it is applied it has a very permanent withdrawal affect.
            </p>
            &nbsp;
            <p>
                Some mathematical discrepancies expected due to rounding and complexity and timing of multiple calculations.
            </p>
            &nbsp;
            <p>
                As of July 15, 2020: Logic adjustments were made so that all investment types (regardless of Portfolio,
                Securities, or Units) follow the same algorithm. The nominal value of the yield is calculated and the yield growth rate
                is applied to this nominal value. This affected the following outputs:
            </p>
            &nbsp;
            <ul>
                <li>
                    Total Dividends/Total Income
                </li>
                <li>
                    Total Distribution
                </li>
                <li>
                    Yield on Cost
                </li>
                <li>
                    Risk Adjusted Basis
                </li>
            </ul>
            <p>
                This change was made to more accurately simulate real world scenarios and adapt industry standards for yield
                calculations.
            </p>
            &nbsp;
            <p>
                Rounding was turned off in the algorithm. Rounding is only applied at the very end of the cycle and right before the
                final values are displayed. This was done to be as accurate as possible with every calculation. It also reduces the
                output difference between Portfolio and Securities scenarios.
            </p>
            &nbsp;
            <p>
                Dividend per share and income per unit were added in the results section and the output table. This shows the
                nominal value for each individual share or unit for a given year. This value is displayed to the tenth of a cent.
            </p>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_40>
        <ion-row #j_41>
            <ion-col #j_42 class="ion-no-padding">
                <ion-button #j_43 color="dark" expand="block" type="button" class="ion-margin" (click)="close()">
                    Close
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>