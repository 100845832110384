<ion-content>
  <!-- <ion-icon class="ion-hide-sm-up mobilehandle" src="assets/icon/Handle.svg"></ion-icon> -->
  <ion-header class="ion-no-border header-top"> 
    <ion-toolbar mode="md" color="pure-white">
        <ion-buttons slot="start">
            <ion-button color="success" routerDirection="back" (click)="goBack()">
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-down">
                </ion-icon>
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-up">
                </ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="dark" class="freedomtitle">
            Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-item class="divider ion-hide-sm-up"></ion-item>
<p class="subTitle mobile-align">Know Thyself</p>
<ion-grid class="mobile-align">
<p>
    The “You” component is critical and its importance in wealth building cannot be
    overstated. At the basic level is getting to know some key facts, goals and temperament
    preferences.
</p>
&nbsp;
<p>
    Some examples included:
</p>
<ul>
    <li>
        Current Earned Income (money you work for)
    </li>
    <li>
        Earned Income goal (increasing Earned Income affects all other ratio based planning)
    </li>
    <li>
        Your Freedom Number (minimum unearned income needed to cover core needs)
    </li>
    <li>
        Your Lifestyle Number (unearned income needed to cover wants and luxuries)
    </li>
    <li>
        Earned Income Optimization plan (steps to increase your earned income)
    </li>
    <!--
    <li>
        Immediate Personal Goal (a non money related short term personal goal)
    </li>-->
    <li>
        Financial Education (your experience level regarding money management, investing and speculating)
    </li>
    <li>
        Primary Goal (your current most important and urgent financial goal)
    </li>
    <li>
        Biggest Challenge (what often gets in your way and prevents you from progress)
    </li>
    <li>
        Lifestyle Preferences (your active or passive investment preferences)
    </li>
    <li>
        Temperament (your patience and detail oriented personality traits)
    </li>
    <li>
        Risk Preference (your sensitivity to volatility)
    </li>
    <li>
        Primary Wealth Strategy (your primary wealth building focus - typically active)
    </li>
    <li>
        Secondary Wealth Strategy (your secondary wealth building focus - typically passive)
    </li>
    <li>
        Legacy Statement (your big WHY, passions, desires, dreams, values and definitions
        for pursuing “true wealth” and mastering the money game)
    </li>
    <li>
        Legacy Model (your intended estate plan and focus: Die & Distribute (traditional estate plan which distributes all assets to heirs), Charity (intending to give all assets to charity), Spend It (I worked hard all my life and "I'm spending my kids inheritance" model), Legacy (the "family office" model which may include some of the prior options as well)
    </li>
</ul>
<p>
    Getting a clear understanding of these attributes will help you in future planning
    and decision making.
</p>
</ion-grid>
</ion-content>