<ion-header>
    <ion-toolbar #j_6>
        <ion-title #j_7>
            Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_2>
        <ion-card-content #j_3>
            <ion-card-title #j_4>
                Content title text
            </ion-card-title>
            Item text
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_9>
        <ion-row #j_10>
            <ion-col #j_11 class="ion-no-padding">
                <ion-button #j_12 color="dark" expand="block" type="button" class="ion-margin" (click)="close()">
                    Close
                </ion-button>
            </ion-col>
            <ion-col #j_13 class="ion-no-padding">
                <ion-button #j_14 color="dark" expand="block" type="button" class="ion-margin" (click)="openMore()">
                    More Info
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>