<ion-content>
  <!-- <ion-icon class="ion-hide-sm-up mobilehandle" src="assets/icon/Handle.svg"></ion-icon> -->
  <ion-header class="ion-no-border header-top"> 
    <ion-toolbar mode="md" color="pure-white">
        <ion-buttons slot="start">
            <ion-button color="success" routerDirection="back" (click)="goBack()">
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-down">
                </ion-icon>
                <ion-icon src="assets/icon/close.svg" class="ion-hide-sm-up">
                </ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title color="dark" class="freedomtitle">
            Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-item class="divider ion-hide-sm-up"></ion-item>
<p class="subTitle mobile-align">Capital Allocation (ND2) - Wealth Account Rules Based System</p>
<div class="mobile-align">
  <p>
    ND2 is the second formula in our non discretionary rules based system. It is how
    we manage our ND1 “Wealth Account” capital to generate Unearned Income (money that
    works for us: ie; interest, dividends, capital gains, rents, royalties, profits)
    and over time, build our fortress balance sheet.
</p>
&nbsp;
<p>
    Assets on your balance sheet are either making you richer or poorer, adding or decreasing
    risk, maximizing or minimizing liquidity.
</p>
&nbsp;
<p>
    ND2 rules consist of T1 cash and cash-like assets (liquidity), T2 cash flow assets
    (investments), T3 appreciating assets (speculations) -
    <i>
        see TWF Money Quadrant.
    </i>
</p>
&nbsp;


        <p><strong>Our TWF system recommends the following target Capital Allocation ND2 ratios:</strong></p>

<ion-grid>
<ion-row class="listratio">
    <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
        <ul>
            <li>10% - T1 Liquidity (and/or chaos hedge, safety)</li>
            <li>70% - T2 Cash Flow Assets (and/or long term core holdings)</li>
            <li>20% - T3 Speculations (and/or short term trades)</li>
        </ul>
    </ion-col>
    <ion-col  size-lg="12" size-md="12" size-sm="12" size-xl="12" size-xs="12">
        <ion-img src="assets/images/cbimage.png"></ion-img>
        <ion-list>
        <ion-item lines="none">
                <div class="legend-item fullwidth">
                    <div class="box" [style.background-color]="'#66C6FF'"></div>
                    <div class="boxText">T1 Liquidity</div>
                    <div class="boxValue">10%</div>
                </div>
        </ion-item>
        <ion-item lines="none">
                <div class="legend-item fullwidth">
                    <div class="box" [style.background-color]="'#5CE593'"></div>
                    <div class="boxText">T2 Cash Flow Assets</div>
                    <div class="boxValue">70%</div>
                </div>
        </ion-item>
        <ion-item lines="none">
                <div class="legend-item fullwidth">
                    <div class="box" [style.background-color]="'#FFCF33'"></div>
                    <div class="boxText">T3 Speculation</div>
                    <div class="boxValue">20%</div>
                </div>
        </ion-item>
    </ion-list>
    </ion-col>
</ion-row>
</ion-grid>
    <p> 
    These are the defaults but you can customize it per your needs.
    </p>
    &nbsp;
    <p>
    Keep in mind your ND2 rules are high level “target” ratios meant to act as a key
    “instrument” on your Wealth Dashboard, letting you know how far on or off track you
    might be, when you might be taking on more risk than you want. You may rarely if
    ever have a balance sheet that matches your target ratios perfectly as things will
    always be in flux. They can also be used to help “tilt” your capital allocations
    during certain market conditions.
</p>
&nbsp;
<p>
    <strong>
        Why does my "Wealth Account" balance not equal my Net Worth on my balance sheet?
    </strong>
</p>
&nbsp;
<p>
    When you add Asset accounts to your Balance Sheet (via +Accounts), for each account
    you select either Consumer, Depreciating Asset, Appreciating Asset, Cash Flow Asset
    (ie; see TWF Money Quadrant). Only Assets marked Consumer or Depreciating with “T1
    liquidity/chaos hedge?” checked will be included in your T1 ratios. Cash Flow Assets
    get included in T2 and Appreciating Assets get included in T3. These are Assets identified
    as part of the “Wealth Account” portion of your balance sheet with strategic importance
    towards helping you achieve financial freedom. Not all “assets” on your balance sheet
    will be included. An automobile for example will be marked as a Depreciating Asset
    but should not be part of your T1 allocation.
</p>
&nbsp;
<p>
    <strong>
        Note.
    </strong>
    If you have a primary residence valued at $250,000 (a Depreciating or Appreciating
    Asset) and a mortgage with a $200,000 balance (a Liability or debt attached to the
    residence), the difference of $50,000 equity can show up 3 different ways: a) part
    of your “Wealth Account” and T3 if marked Appreciating Asset, b) part of your “Wealth
    Account” and T1 if marked Consumer or Depreciating Asset AND “T1 liquidity/chaos
    hedge?” is checked, or c) not at all if marked Consumer or Depreciating Asset and
    “T1 liquidity/chaos hedge?” is NOT checked.
</p>
&nbsp;
<p>
    <strong>
        Another example:
    </strong>
    If you have a primary residence but part of it is rented out and generating positive
    cash flow income, then you can simply add 2 separate Asset accounts both representing
    the total value of the home. Mark the first account as you would normally for a residence
    (either Depreciating or Appreciating Asset) but mark the second account as a Cash
    Flow Asset with its respective values (ie; yield). In this example, the same asset
    can take on more than one characteristic and be displayed accordingly in your ND2
    ratios. The Cash Flow Asset portion and its values will also show up in your Compound
    Return (MOAC) projections and charts (if not marked “Exclude from compound return
    calc?”).
</p>
</div>
</ion-content>