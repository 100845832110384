import {
    NgModule
} from '@angular/core';
import {
    TextWithHelpIconComponentModule
} from '../custom_component/TextWithHelpIcon/TextWithHelpIcon.module';
@NgModule({
    declarations: [],
    exports: [
        TextWithHelpIconComponentModule,
    ],
    imports: []
})
export class CustomComponentsModule {}