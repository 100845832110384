<ion-header>
    <ion-toolbar #j_104>
        <ion-title #j_105>
            Debt Payoff Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_99>
        <ion-card-content #j_100>
            <ion-card-title #j_101>
                Debt Payoff - Compound Debt Elimination (CDE)
            </ion-card-title>
            <div #j_102>
                <p>
                    Restructuring the balance sheet for cash flow
                </p>
                <p>
                    Not all balance sheets are created equal. Our proprietary formulas help you convert
                    debt into wealth by accelerating the debt payoff so you can compound asset growth
                    more quickly.
                </p>
                <br>
                <ol>
                    <li>
                        10% giving account - to help those in need
                    </li>
                    <li>
                        10% wealth account (20% when debt free) - to develop Unearned Income
                    </li>
                    <li>
                        10% debt account - to accelerate debt payoff
                    </li>
                    <li>
                        70% living expenses - to cover bills
                    </li>
                </ol>
                <p>
                    You are free to customize the above ratios but that is the proven and recommended
                    formula. You can also add custom accounts as well.
                </p>
                <p>
                    Action Steps:
                </p>
                <ol>
                    <li>
                        Open new accounts, close uneeded ones, consolidate what can be consolidated.
                    </li>
                    <li>
                        Label the accounts at your bank, brokerage or fintech solution.
                    </li>
                    <li>
                        Setup automated transfers according to the ratios above to trigger every time you
                        make a deposit.
                    </li>
                </ol>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_107>
        <ion-row #j_108>
            <ion-col #j_109 class="ion-no-padding">
                <ion-button #j_110 color="dark" expand="block" type="button" class="ion-margin"
                (click)="close()">
                    Close
                </ion-button>
            </ion-col>
            <ion-col #j_111 class="ion-no-padding">
                <ion-button #j_112 color="dark" expand="block" type="button" class="ion-margin"
                (click)="openMore()">
                    More Info
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>