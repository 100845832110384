// Very important content
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
console.log(
  `%c--- BookPhysio build ${environment.buildNumber} ---`,
  'font-weight: bold'
);
if (environment.production) {
  window.console.log = () => {};
  enableProdMode();
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
