<ion-header>
    <ion-toolbar #j_120>
        <ion-title #j_121>
            Capital Allocation Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_115>
        <ion-card-content #j_116>
            <ion-card-title #j_117>
                Capital Allocation (ND2) - Wealth Account Rules Based System
            </ion-card-title>
            <div #j_118>
                <p>
                    ND2 is the second formula in our non discretionary rules based system. It is how
                    we manage our Wealth Account capital to generate Unearned Income (money that works
                    for us: ie; interest, dividends, capital gains, rents, royalties, profits) and build
                    a fortress balance sheet.
                </p>
                <p>
                    Assets on your balance sheet are either making you richer or poorer, adding or decreasing
                    risk, maximizing or minimizing liquidity.
                </p>
                <p>
                    ND2 rules consists of T1 cash and cash-like assets (liquidity), T2 cash flow assets
                    (investments), T3 appreciating assets (speculations).
                </p>
                <p>
                    We recommend an example ratio of 10/70/20 (10% T1 Liquidity, T2 70% Cashflow Assets,
                    20% T3 Speculations) but you are free to customize it.
                </p>
                <p>
                    Why does my "Wealth Account" balance not equal my Net Worth on my balance sheet?
                </p>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_123>
        <ion-row #j_124>
            <ion-col #j_125 class="ion-no-padding">
                <ion-button #j_126 color="dark" expand="block" type="button" class="ion-margin"
                (click)="close()">
                    Close
                </ion-button>
            </ion-col>
            <ion-col #j_127 class="ion-no-padding">
                <ion-button #j_128 color="dark" expand="block" type="button" class="ion-margin"
                (click)="openMore()">
                    More Info
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>