<ion-content class="pwa-dialog ion-padding" scrollEvents="false" scrollX="false"
scrollY="false">
    <img #j_1161 class="pwa-install-logo" src="assets/images/icon180.png" alt="Image error">
    <h2 #j_1162 class="pwa-dialog-title">
        Install Wealth Builder
    </h2>
    <p #j_1163 class="pwa-dialog-body-text">
        Install this app on your home screen for easy, quick access
    </p>
    <div #j_1164 class="ion-text-center install-note">
        <ion-text #j_1165>
            Press
        </ion-text>
        <ion-icon #j_1166 name="share" size="large" mode="ios" class="ios-share-icon">
        </ion-icon>
        <ion-text #j_1167>
            , then &quot;Add to home screen&quot;
        </ion-text>
    </div>
</ion-content>