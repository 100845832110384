<ion-header>
    <ion-toolbar #j_22>
        <ion-title #j_23>
            Freedom Number Tutorial
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card #j_17>
        <ion-card-content #j_18>
            <ion-card-title #j_19>
            </ion-card-title>
            <ion-text #j_20>
                Your Freedom Number is the minimum monthly Unearned Income (money that works for
                you) needed to cover your core needs. It is the first specific target number and
                milestone in the TWF money mastery system and is used to plot your financial independence
                plan.
                <br>
                <br>
                Using Maslow's Hierarchy of Needs model (ie; “food, clothing, shelter”), the wealth
                builder makes a clear distinction between Freedom (core needs) and Lifestyle (luxury
                wants).
                <br>
                <br>
                Ie; If my core needs are covered, I no longer need to “work for money”, I am free
                to contribute to society or follow a passion. I am working for fulfillment not survival.
                If for some reason I cannot physically work, my core needs are covered.
                <br>
                <br>
                How it’s used? Your Freedom Number is used to plot your progress when running calculations
                and projections on your existing Assets as well as “forecasting” and running simulations
                on possible A/B “what if” scenarios.
            </ion-text>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-grid #j_25>
        <ion-row #j_26>
            <ion-col #j_27 class="ion-no-padding">
                <ion-button #j_28 color="dark" expand="block" type="button" class="ion-margin" (click)="close()">
                    Close
                </ion-button>
            </ion-col>
            <ion-col #j_29 class="ion-no-padding">
                <ion-button #j_30 color="dark" expand="block" type="button" class="ion-margin" (click)="openMore()">
                    More Info
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>