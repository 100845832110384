<ion-header>
    <ion-toolbar #j_1201 color="primary">
        <ion-buttons #j_1202 slot="start">
            <ion-button #j_1203 color="dark" (click)="dismiss()">
                Close
            </ion-button>
        </ion-buttons>
        <ion-title #j_1204 color="dark">
            {{ title || &quot;About this resource&quot; }}
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <p #j_1199 class="ion-margin" inAppBrowserLink="" appMarked="">
        {{ content }}
    </p>
</ion-content>